.searchPlaceholder {
  @apply px-6 md:px-8 lg:px-12 py-8 md:py-8 lg:py-8;
}

.searchPlaceholder div {
  @apply rounded-md bg-neutral-300 bg-gradient-to-r from-neutral-300 from-20% via-neutral-200 via-30% to-neutral-300 to-40%;
  background-repeat: no-repeat;
  background-size: 800px 600px;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

.inputContainer input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
