.Toast::before {
  @apply block w-2 bg-green-500 absolute top-0 left-0 rounded-l-md h-full;
  content: "";
}

.Toast {
  width: auto;
  min-width: 24rem;
  max-width: 32rem;
}

.ToastOpen {
  animation-timing-function: cubic-bezier(0, 0.2, -0.4, 0.1);
  animation: open 0.8s forwards;
}

.ToastClose {
  animation-timing-function: cubic-bezier(0, 0.2, -0.4, 0.1);
  animation: close 0.8s forwards;
}

.CloseButtonA11y {
  @apply hidden;
}

@keyframes open {
  0% {
    right: -100%;
    display: hidden;
  }
  1% {
    display: block;
  }
  100% {
    right: 0;
  }
}

@keyframes close {
  0% {
    right: 0;
    display: block;
  }
  100% {
    right: -100%;
    display: hidden;
  }
}

@media screen and (max-width: 768px) {
  .Toast {
    min-width: calc(100% - 2rem);
  }
}
